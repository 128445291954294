<template>
  <div>
    <div class="intro-y">
      <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:last:border-dark-5">
        <h2 class="font-medium text-base mr-auto">
          Crear o actualizar ciudades
        </h2>
      </div>
    </div>
    <div id=" vertical-form" class="p-5 relative" >
      <div class="preview">
        <Form :validation-schema="validator" @submit="handleRegister">
          <div>
            <label for="vertical-form-code" class="form-label">
              Código:
            </label>
            <Field id="vertical-form-code" type="number" name="code" class="form-control" placeholder="" v-model="formCity.id"/>
            <ErrorMessage class="text-theme-6" name="code"/>
          </div>
          <div>
            <label for="vertical-form-name" class="form-label">
              Nombre:
            </label>
            <Field id="vertical-form-name" name="name" type="text" class="form-control" placeholder="" v-model="formCity.name"/>
            <ErrorMessage name="name" class="text-theme-6"/>
          </div>
          <div class="mt-2">
            <label class="form-label">
              Departamentos
            </label>
            <tail-select v-model="formCity.pkCommDep" :options="{ search: true }">
              <option :value="null">Seleccionar</option>
              <option v-for="(department, i) in departments" :key="i" :value="department.id">
                {{department.name}}
              </option>
            </tail-select >
            <Field  class="form-control" name="pkCommDep" type="hidden"  v-model="formCity.pkCommDep"/>
            <ErrorMessage name="pkCommDep" class="text-theme-6"/>
          </div>
          <div class="absolute right-5 flex space-x-4">
            <button class="btn btn-danger mt-5">
              Cancelar
            </button>
            <button class="btn btn-primary mt-5" type="submit">
              Registrar
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { formCity, getListPaginateCitys, citys, handleRegister } from '../../hooks'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as Yup from 'yup'
export default {
  name: 'formularioCiudades',
  props: {
    departments: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    Form, Field, ErrorMessage
  },
  setup () {
    /* methods */
    const validator = Yup.object().shape({
      name: Yup.string('El campo es obligatorio').required('El campo es obligatorio').max(255),
      pkCommDep: Yup.number().required('El campo es obligatorio'),
      code: Yup.number('El campo es numérico').required('El campo es obligatorio')
    })
    /* returns */
    return {
      formCity,
      citys,
      getListPaginateCitys,
      handleRegister,
      validator
    }
  }
}
</script>
