<template>
  <div class="v-for col-span-12 xl:col-span-4 md:col-span-6 zoom-in" v-for="item in list" :key="item.id">
    <div class="grid grid-rows-2 grid-flow-col gap-2 rounded-lg px-3 py-3 bg-theme-13 bg-opacity-10">
      <div class="row-span-1 col-span-2">
        Nombre: {{item.name}}
      </div>
      <div class="col-span-2">
        Codigo: {{item.id}}
      </div>
      <div class="row-span-2">
        <div class="">
          <a class="flex flex-row-reverse text-theme-10" title="Editar">
            <edit-icon size="1.5x" class="custom-class"/>
          </a>
          <a class="flex flex-row-reverse text-theme-6" title="Eliminar">
            <delete-icon size="1.5x" class="custom-class"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cardsList',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
