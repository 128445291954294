import { computed, reactive, ref } from 'vue'
import listPaginateCitys from './services/listPaginateCitys'
import createCity from './services/createCity'
import { error as messageError, success } from '../../../../../libs/mensajes'
import { Form, Field, ErrorMessage } from 'vee-validate'
/* DATA */
const citys = ref({
  last_page: 1,
  current_page: 1,
  total: 0,
  per_page: 30,
  data: []
})
const formCity = reactive({
  name: '',
  id: 0,
  pkCommDep: ''
})
const formularioCitys = ref(null)

/* COMPUTEDS */
const paginate = computed(() => {
  return {
    current_page: citys.value.current_page,
    last_page: citys.value.last_page,
    per_page: citys.value.per_page,
    total: citys.value.total
  }
})
/* METHODS */
const getListPaginateCitys = (page) => {
  const params = { page: page, paginate: 30, order: 'name,asc' }
  listPaginateCitys(params).then(({ data, statusText, status }) => {
    citys.value = data
  }).catch((error) => {
    console.error(error)
  })
}
const handleRegister = () => {
  createCity(formCity).then(({ data, status, statusText }) => {
    console.log(status)
    if ([200, 201].includes(status)) {
      success(data.message, 'Éxito')
      handleCancel()
      getListPaginateCitys()
    } else {
      messageError(data.message, 'Error')
      console.error(statusText)
    }
  }).catch((e) => {
    messageError('a ocurrido un error', 'Error')
    console.error(e)
  })
}
const handleCancel = (resetForm) => {
  formCity.id = 0
  formCity.name = ''
  formCity.pkCommDep = ''
  resetForm({ errors: {} })
}
/* RETURNS */
export {
  citys,
  paginate,
  formCity,
  getListPaginateCitys,
  handleRegister,
  handleCancel,
  formularioCitys,
  Form, Field, ErrorMessage
}
