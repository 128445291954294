<template>
  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-12 xxl:col-span-5 box" style="height: 400px;">
      <formulario :departments="departments.data"/>
    </div>
    <div class="col-span-12 xxl:col-span-7 box">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <!-- inicio del encabezado -->
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:last:border-dark-5">
              <h2 class="font-medium text-base mr-auto">
                Listado de Ciudades
              </h2>
            </div>
            <!-- inico del listado -->
            <div class="grid grid-cols-12 gap-4 mt-5 px-5 py-5">
              <cardsList :list="citys.data"/>
            </div>
            <!-- fin del listado -->
            <div class="grid grid-cols-12 gap-4 mt-5 rounded px-5 py-3" v-show="citys.data.length > 0">
              <Paginator :current-page="paginate.current_page" :total="paginate.total" :last-page="paginate.last_page" @change-page="getListPaginateCitys"/>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import { onMounted } from 'vue'
import { getListPaginateCitys, citys, paginate } from './hooks'
import { getListDepartment, departments } from '../departments/hooks'
import cardsList from './components/cardsList'
import formulario from './components/formulario'
export default {
  name: 'citys',
  components: {
    cardsList,
    formulario
  },
  setup () {
    onMounted(() => {
      getListDepartment()
      getListPaginateCitys()
    })
    return {
      getListPaginateCitys,
      getListDepartment,
      citys,
      paginate,
      departments
    }
  }
}
</script>
<style scoped>

</style>
